import React from 'react';
import './CourseCheckboxes.css';

const CourseCheckboxes = ({ courses, selectedItems, handleItemChange }) => {
  const maxSelectionsReached = selectedItems.length >= 3;

  return (
    <div className="form-control course-checkboxes">
      {courses.map((courseObj, index) => {
        const courseName = courseObj.courseName;
        const isChecked = selectedItems.includes(courseName);
        const isDisabled = !isChecked && maxSelectionsReached; // Disabilita se non è selezionato e si è raggiunto il massimo

        return (
          <label key={index}>
            <input
              type="checkbox"
              value={courseName}
              checked={isChecked}
              onChange={() => handleItemChange(courseName)}
              disabled={isDisabled}
            />
            {courseName}
          </label>
        );
      })}
    </div>
  );
};

export default CourseCheckboxes;