import React, { useState } from "react";
// import { Link } from 'react-router-dom'; // Importa Link
import { useHistory } from "react-router-dom"; // Importa useHistory
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Modal from "../../shared/components/UIElements/Modal";
import { useHttpClient } from "../../shared/hooks/http-hook";

import Input from "../../shared/components/FormElements/Input";
import Logo from "./Manpower_Logo3.png";
import CourseCheckboxes from "../../shared/components/FormElements/CourseCheckboxes"; // Modifica con il percorso corretto

import Button from "../../shared/components/FormElements/Button";
import { VALIDATOR_REQUIRE, VALIDATOR_EMAIL, VALIDATOR_CF } from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import "./MainForm.css";

const MainForm = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [courses, setCourses] = useState([]);
  const [slotOccupati, setSlotOccupati] = useState(4);
  const [limitMessage, setLimitMessage] = useState(false);
  const [listaAttesa, setListaAttesa] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);

  const history = useHistory(); // Ottieni la history

  const [formState, inputHandler] = useForm(
    {
      nome: {
        value: "",
        isValid: false,
      },
      cognome: {
        value: "",
        isValid: false,
      },
      codiceFiscale: {
        value: "",
        isValid: false,
      },
      domicilio: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const [isFlipped, setIsFlipped] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const closeLimitModal = () => {
    setShowLimitModal(false);
    setLimitMessage("");
  };

  const handleItemChange = (item) => {
    setSelectedItems((prevItems) => {
      if (prevItems.includes(item)) {
        return prevItems.filter((i) => i !== item);
      } else {
        return prevItems.length < 3 - slotOccupati ? [...prevItems, item] : prevItems;
      }
    });
  };

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const placeSubmitHandler = async (event) => {
    event.preventDefault();

    const transformedInputs = {
      nome: formState.inputs.nome.value,
      cognome: formState.inputs.cognome.value,
      cf: formState.inputs.codiceFiscale.value.toUpperCase(), // Assumi che cf sia uguale a codiceFiscale
      comune: "TTT", // Valore statico, poiché non è fornito nell'input originale
      prov: formState.inputs.domicilio.value,
      email: formState.inputs.email.value,
    };
    try {
      const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + "/enroll/cfcheck", "POST", JSON.stringify(transformedInputs), {
        "Content-Type": "application/json",
      });
      console.log(responseData.papabile);

      if (responseData.papabile.lista_attesa === "abilitata!limitesuperato") {
        setLimitMessage(responseData.papabile.lista_attesa);
        setListaAttesa(responseData.papabile.lista_attesa);
        setShowLimitModal(true);
      }
      if (responseData && responseData.papabile.corsiDisponibili.length > 0) {
        setCourses(responseData.papabile.corsiDisponibili);
        setSlotOccupati(responseData.papabile.slotOccupati_ed_attuale.length);
      }
      toggleFlip();
    } catch (err) {}
  };

  const submitSelection = async (event) => {
    event.preventDefault();

    const submissionData = {
      userData: {
        nome: formState.inputs.nome.value,
        cognome: formState.inputs.cognome.value,
        cf: formState.inputs.codiceFiscale.value.toUpperCase(),
        domicilio: formState.inputs.domicilio.value,
        email: formState.inputs.email.value,
      },
      selectedCourses: selectedItems, // ID o nomi dei corsi selezionati
    };
    submissionData.listaAttesa = listaAttesa;
    console.log(submissionData);
    try {
      // const responseData = await sendRequest(
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/enroll/submit", // Endpoint del backend per l'invio della candidatura
        "POST",
        JSON.stringify(submissionData),
        {
          "Content-Type": "application/json",
        }
      );
      history.push({
        pathname: "/congratulazioni",
        state: { fromForm: true }, // Passa uno stato per indicare che il redirect è avvenuto dall'invio del form
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}

      <div className={`form-container ${isFlipped ? "flipped" : ""}`}>
        <div className="header-section">
          {
            <div className="header-section">
              <img src={Logo} alt="FAD Gift Logo" className="logo-vero" />
              <h1 className="form-title">Candidatura FAD Gift 2024</h1>
              <div className="form-description container-par">
                <p>
                  Grazie per voler aderire all&rsquo;iniziativa <strong>Manpower PREMIA il tuo TALENTO!</strong>
                  <br />
                  Ti ricordiamo che l&rsquo;iniziativa &egrave; riservata esclusivamente a coloro che hanno ricevuto direttamente la e-mail, con un
                  contratto di somministrazione a <strong>tempo indeterminato in corso.</strong>
                  <br />
                  Puoi scegliere di iscriverti fino ad un massimo di <strong>3 corsi</strong>&nbsp;tra un elenco di 16 titoli che troverai dopo aver
                  inserito i tuoi dati anagrafici.
                  <br />I corsi saranno fruibili nel periodo tra il 25 ottobre e il 24 novembre.
                </p>
                <p>
                  I corsi potranno essere seguiti in qualsiasi momento al di fuori del tuo orario lavorativo, 7 giorni su 7 (e-learning asincrono) e
                  hanno una durata di 16h ciascuno. Gli stessi potranno essere seguiti da smartphone, PC o tablet.
                  <br />
                  Per ogni corso seguito <strong>integralmente</strong>, con superamento del test e attestato conseguito, sarai premiato con un bonus
                  di <strong>100,00 euro lorde</strong>, che verr&agrave; erogato nei mesi successivi alla fine del corso.
                  <br />
                  Per consultare l&rsquo;elenco dei corsi e completare la tua iscrizione, ti chiediamo di compilare i campi sottostanti con attenzione
                  e precisione. Assicurati di fornire tutte le informazioni richieste in modo accurato, prestando particolare attenzione ad inserire
                  correttamente il tuo&nbsp;
                  <strong>
                    <u>Codice Fiscale.</u>
                  </strong>
                  <br />
                  Le iscrizioni rimarranno aperte <strong><u>fino ad esaurimento dei posti disponibili</u></strong> e non oltre il 20 ottobre 2024.
                  <br />
                  Nel caso in cui l&rsquo;iscrizione abbia avuto successo riceverai entro massimo il giorno 24 ottobre
                  il link di accesso al/i corso/i a cui hai aderito con indicazione delle date di inizio e fine entro cui fruire del/i corso/i.
                </p>
              </div>
            </div>
          }
        </div>
        {!isFlipped ? (
          <form className="place-form" onSubmit={placeSubmitHandler}>
            <Input
              id="nome"
              element="input"
              type="text"
              label="Nome"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Inserire il proprio nome"
              onInput={inputHandler}
            />
            <Input
              id="cognome"
              element="input"
              type="text"
              label="Cognome"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Inserire il proprio cognome"
              onInput={inputHandler}
            />
            <Input
              id="codiceFiscale"
              element="input"
              label="Codice Fiscale"
              validators={[VALIDATOR_CF()]}
              errorText="Inserire il proprio codice fiscale"
              onInput={inputHandler}
            />
            <Input
              id="domicilio"
              element="input"
              label="Provincia di domicilio"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Inserire la propria provincia di domicilio"
              onInput={inputHandler}
              list="domicilio-options"
            />
            <Input
              id="email"
              element="input"
              label="Email"
              validators={[VALIDATOR_EMAIL()]}
              errorText="Inserire la propria email"
              onInput={inputHandler}
              list="email-options"
            />
            <Button type="submit" disabled={!formState.isValid}>
              Avanti
            </Button>
          </form>
        ) : (
          <form className="place-form" onSubmit={submitSelection}>
            {limitMessage && (
              <Modal
                show={showLimitModal}
                onCancel={closeLimitModal}
                // header={props.address}
                contentClass="place-item__modal-content"
                footerClass="place-item__modal-actions"
                footer={
                  <Button className="button-no-border" onClick={closeLimitModal}>
                    CHIUDI
                  </Button>
                }
              >
                <div className="modal-body">
                  <h2 className="modal-title">Attenzione posti esauriti</h2>
                  <p className="modal-text">
                    Purtroppo è stato superato il numero massimo di iscrizioni! E' possibile iscriversi alla <strong>lista d'attesa</strong> così da
                    essere aggiunti nel caso in cui venisse aperta una nuova edizione.
                    <br />
                    <br />
                    Per modifiche o per problemi tecnici contattare il supporto alla mail
                    <a href="mailto:supporto.fad@foritgroup.it"> supporto.fad@foritgroup.it</a>
                  </p>
                </div>
              </Modal>
            )}
            <CourseCheckboxes
              courses={courses} // Passa i corsi al componente CourseCheckboxes
              selectedItems={selectedItems}
              handleItemChange={handleItemChange}
            />
            <Button type="button" onClick={submitSelection}>
              Invia Candidatura
            </Button>
          </form>
        )}
      </div>
    </React.Fragment>
  );
};

export default MainForm;
